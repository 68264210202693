/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from 'react';
import { FeedId } from '@mta-live-media-manager/shared';
import { FloatingWrapper, useMention } from '@remirror/react';
import { MentionType } from 'generated/global-types';
import { ReactComponent as AdaIcon } from 'images/icon_ada.svg';
import { ReactComponent as SuttleBusIcon } from 'images/icon_bus.svg';
import { css } from '@emotion/react';
import Bullet, { TinyBullet } from 'components/common/Bullet';
import { isTrain } from 'utils/feed-switches';
import { useTheme } from 'contexts/Theme';
import { usePaste } from 'hooks/use-paste';
import { ISuggestion } from './editor-utils';
import classNames from 'classnames';
import { multiAgencyCSS } from './editor-styles.styled';

export const GTFS_MATCHER_NAME = 'gtfs';

const MULTI_AGENCY_ORDER = {
  [FeedId.NYCTSubway]: [FeedId.NYCTBus, FeedId.LIRR, FeedId.MNR],
  [FeedId.NYCTBus]: [FeedId.NYCTSubway, FeedId.MNR, FeedId.LIRR],
  [FeedId.MNR]: [FeedId.NYCTSubway, FeedId.NYCTBus, FeedId.LIRR],
  [FeedId.LIRR]: [FeedId.NYCTSubway, FeedId.NYCTBus, FeedId.MNR],
};

export const getCustomIconMentions = (
  feedId: FeedId,
  gtfsQuery: string,
  activeIndex: number,
): ISuggestion[] => {
  const customIconsMentions: ISuggestion[] = [];

  if (gtfsQuery && gtfsQuery.toLowerCase() === 'ada') {
    customIconsMentions.push({
      feedId,
      id: 'ICON:ADA',
      name: 'ADA',
      label: '\u200cADA\u200c',
      replacementType: 'partial',
      'data-bullet-title': MentionType.ADA,
      'data-bullet-type': MentionType.ADA,
      'data-bullet-display': 'ada',
      active: activeIndex === 0,
    });
  } else if (
    gtfsQuery &&
    (gtfsQuery.toLowerCase() === 'shuttle' ||
      gtfsQuery.toLowerCase() === 'shuttlebus')
  ) {
    customIconsMentions.push({
      feedId,
      id: 'ICON:SHUTTLE_BUS',
      name: 'SHUTTLE_BUS',
      label: '\u200cSHUTTLE_BUS\u200c',
      replacementType: 'partial',
      'data-bullet-title': MentionType.SHUTTLE_BUS,
      'data-bullet-type': MentionType.SHUTTLE_BUS,
      'data-bullet-display': 'shuttle_bus',
      active: activeIndex === 0,
    });
  } else if (gtfsQuery && gtfsQuery.toLowerCase().startsWith('airp')) {
    customIconsMentions.push({
      feedId,
      id: 'ICON:AIRPLANE',
      name: 'AIRPLANE',
      label: '\u200cAIRPLANE\u200c',
      replacementType: 'partial',
      'data-bullet-title': MentionType.AIRPLANE,
      'data-bullet-type': MentionType.AIRPLANE,
      'data-bullet-display': 'airplane',
      active: activeIndex === 0,
    });
  }

  return customIconsMentions;
};

type FormattedTrainMention = {
  plain: string;
  styled: JSX.Element;
};

const formattedTrainMention = (
  suggestion: ISuggestion,
): FormattedTrainMention => {
  const { 'data-bullet-meta': meta, trainNum } = suggestion;
  const { firstStop, lastStop } = meta ?? ({} as any);

  const StyledStop: React.FC<{
    children: string;
  }> = ({ children }) => (
    <span
      css={css`
        font-style: italic;
      `}
    >
      {children}
    </span>
  );

  const StyledTrainMention: React.FC<{
    trainNumber?: string;
    children: React.ReactNode;
  }> = ({ trainNumber, children }) => {
    const theme = useTheme();

    return (
      <span
        css={css`
          margin-right: 4px;
        `}
      >
        {trainNumber && (
          <span
            css={css`
              margin-right: 8px;
              min-width: 40px;
              display: inline-block;
            `}
          >{`#${trainNumber}`}</span>
        )}
        <span
          css={css`
            font-weight: ${theme.typography.weights.normal};
          `}
        >
          {children}
        </span>
      </span>
    );
  };

  const plain =
    firstStop && lastStop
      ? `${firstStop.time} train from ${firstStop.name} to ${lastStop.name}`
      : trainNum || '';

  const styled = (
    <StyledTrainMention trainNumber={trainNum}>
      <span>{`${firstStop.time} — `}</span>
      <StyledStop>{firstStop.name}</StyledStop>
      <span> to </span>
      <StyledStop>{lastStop.name}</StyledStop>
    </StyledTrainMention>
  );

  return { plain, styled };
};

const stringForType = (type: MentionType): string | null => {
  switch (type) {
    case MentionType.ROUTE:
      return 'Route';
    case MentionType.TRIP:
      return 'Train';
    case MentionType.STOP:
      return 'Station';
    case MentionType.ADA:
      return 'ADA';
    case MentionType.SHUTTLE_BUS:
      return 'Shuttle Bus';
    case MentionType.AIRPLANE:
      return 'Airplane';
    default:
      return null;
  }
};

const SuggestionItem: React.FC<{
  item: ISuggestion;
  itemProps: any;
  isSelected: boolean;
  isHovered: boolean;
  isFirst: boolean;
}> = ({ item, isSelected, itemProps, isHovered, isFirst }) => {
  const { 'data-bullet-type': type } = item;
  const theme = useTheme();

  const nameEl = (() => {
    if (type === MentionType.ROUTE) {
      return (
        <Bullet
          overrideBulletData={{
            color: item.backgroundColor as string,
            textColor: item.textColor as string,
            name: item.label,
            gtfsId: item.id,
            feedId: item.feedId,
          }}
          feedId={item.feedId}
          routeId={item.id}
        />
      );
    }
    if (type === MentionType.TRIP) {
      return formattedTrainMention(item).styled;
    }
    if (type === MentionType.ADA) {
      return <AdaIcon width="20" height="20" />;
    }
    if (type === MentionType.SHUTTLE_BUS) {
      return <SuttleBusIcon width="20" height="20" />;
    }
    return (
      <span>
        <span
          css={css`
            margin-right: 4px;
          `}
        >
          {item.label}
        </span>
        {isTrain(item.feedId) &&
          item.relatedRouteIds?.map((routeId) => (
            <TinyBullet
              key={routeId}
              routeId={routeId}
              overrideBulletData={{ feedId: item.feedId }}
            />
          ))}
      </span>
    );
  })();

  const namedType = stringForType(type as MentionType);
  return (
    <div
      {...itemProps}
      css={css`
        display: flex;
        border-top: ${!isFirst ? `1px solid ${theme.colors.border}` : 'none'};
        background-color: ${isSelected || isHovered
          ? '#e7eff6'
          : theme.colors.white};
      `}
    >
      <button
        css={css`
          ${theme.typography.sizes.small};
          font-weight: ${theme.typography.weights.bold};
          text-align: left;
          padding: 8px 16px;
          width: ${type === MentionType.SHUTTLE_BUS ? '65%' : '100%'};
          outline: none;
        `}
        type="button"
      >
        {nameEl}
      </button>
      {namedType && (
        <span
          css={css`
            align-self: center;
            padding-right: 16px;
            color: #777;
            ${theme.typography.sizes.xsmall};
          `}
        >
          {namedType}
        </span>
      )}
    </div>
  );
};

type ResultsByFeed = { name: FeedId; items: ISuggestion[] };

const NoResultsPanel = () => {
  return (
    <div className="no-results">
      <div>No results were found</div>
    </div>
  );
};

const Suggestions: React.FC<{
  isCrossAgency?: boolean;
  onChange: (query: string, activeIdx: number, suggestName: string) => void;
  isHidden?: boolean;
  feedId: FeedId;
  items: ISuggestion[];
}> = ({ items, onChange, isCrossAgency, feedId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const feeds: ResultsByFeed[] = MULTI_AGENCY_ORDER[feedId!].map((feed) => {
    return {
      name: feed,
      items: items.filter((i) => i.feedId === feed),
    };
  });

  useEffect(() => {
    if (isCrossAgency && items?.length) {
      const indexWithItems = feeds.findIndex((v) => v.items.length);
      setActiveTab(indexWithItems === -1 ? 0 : indexWithItems);
    }
  }, [isCrossAgency, items?.length]);

  // only give the mention extension _active_ items for it to render.
  const activeItems = isCrossAgency ? feeds[activeTab].items : items;
  // this is to pad the results if they're < 10 in count for a single
  // agency.
  const maxItems = activeItems.length >= 10 ? 0 : 10 - activeItems.length;
  const blankItems = new Array(maxItems!).fill(0);

  const {
    state,
    getMenuProps,
    getItemProps,
    indexIsSelected,
    index,
    itemIsHovered,
  } = useMention({
    items: activeItems,
  });

  usePaste();

  useEffect(() => {
    if (!state) {
      return;
    }

    onChange(state.query.partial ?? '', index, state.name);
  }, [state]);

  const enabled = Boolean(state);
  return (
    <FloatingWrapper
      renderOutsideEditor
      enabled={enabled}
      positioner="cursor"
      placement="bottom-end"
      containerClass={isCrossAgency ? 'cross-agency-container' : ''}
    >
      {state?.name === 'cross-agency' && (
        <div
          css={multiAgencyCSS}
          className="suggestions cross-agency-suggestions"
        >
          {/* tabs */}
          {feeds.map((feed, idx) => {
            return (
              <div
                className={classNames(
                  'agency',
                  idx === activeTab ? 'active-agency' : 'inactive-agency',
                )}
              >
                <button
                  className="agency-tab"
                  type="button"
                  onClick={() => {
                    setActiveTab(idx);
                  }}
                >
                  {feed.name.toUpperCase()} ({feed.items.length})
                </button>
              </div>
            );
          })}

          <div className="cross-agency">
            <div {...getMenuProps()} className="suggestions">
              {activeItems.length ? (
                activeItems
                  .concat(blankItems)
                  .map((item, idx) => (
                    <SuggestionItem
                      key={item.id}
                      isFirst={idx === 0}
                      item={item}
                      isSelected={indexIsSelected(idx)}
                      isHovered={itemIsHovered(item)}
                      itemProps={getItemProps({ item, index: idx })}
                    />
                  ))
              ) : (
                <NoResultsPanel />
              )}
            </div>
          </div>
        </div>
      )}
      {state?.name === 'gtfs' && (
        <div {...getMenuProps()} className="suggestions">
          {items.map((item, idx) => {
            const isSelected = indexIsSelected(idx);
            const itemProps = getItemProps({
              item,
              index: idx,
            });

            return (
              <SuggestionItem
                key={item.id}
                isFirst={idx === 0}
                isHovered={itemIsHovered(item)}
                item={item}
                itemProps={itemProps}
                isSelected={isSelected}
              />
            );
          })}
        </div>
      )}
    </FloatingWrapper>
  );
};

export default Suggestions;
